import Cookies from "js-cookie";

export const onClientEntry = () => {
  const googleConsent = Cookies.get("googleConsent");
  //const googleTagConsent = Cookies.get("googleTagConsent");

  if (googleConsent === "accepted") {
    console.log("onClientEntry executed ...");
    //} && googleTagConsent === "accepted") {
    // Load Google Analytics script
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=AW-932983784`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-932983784", {
        anonymize_ip: true,
        cookie_expires: 0,
      });
    };
  }
};
